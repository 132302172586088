<template>
  <div id="chart" style="width: 450px">
    <apexchart
      @dataPointSelection="dataPointSelection"
      type="pie"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import db from "../../../../firebaseInit";
import FirebaseDB from "../../../utils/firebaseDB";
import NodesName from "../../../utils/nodesName";
import Constants from "./../../../utils/constants";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
export default {
  name: "ChartListProduits",
  data: function () {
    return {
      series: [],
      chartOptions: {
        colors: [
          "#2fbccd",
          "#f68c1f",
          "#51b848",
          "#7750a0",
          "#f06ba8",
          "#178642",
          "#c4202c",
        ],
        chart: {
          width: "380",
          type: "pie",
        },
        labels: [
          Constants.LIBELLE_CREE,
          Constants.LIBELLE_EN_COURS_DE_PREPARATION,
          Constants.LIBELLE_EN_ATTENTE_DE_LIVRAISON,
          Constants.LIBELLE_EN_COURS_DE_LIVRAISON,
          Constants.LIBELLE_RETOURNEE,
          Constants.LIBELLE_LIVREE,
          Constants.LIBELLE_ANNULEE,
        ],
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5,
            },
          },
        },
        title: {
          text: "Statut commande",
          align: "left",
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: "bottom",
        },
      },
    };
  },
  methods: {
    dataPointSelection(event, chartContext, config) {
      localStorage.setItem("listeFilter", Constants.ETAT_COMMANDE);
      var statut;

      switch (config.dataPointIndex) {
        case 0:
          {
            statut = Constants.CREE;
          }
          break;
        case 1:
          {
            statut = Constants.EN_COURS_DE_PREPARATION;
          }
          break;
        case 2:
          {
            statut = Constants.EN_ATTENTE_DE_LIVRAISON;
          }
          break;
        case 3:
          {
            statut = Constants.EN_COURS_DE_LIVRAISON;
          }
          break;
        case 4:
          {
            statut = Constants.RETOURNEE;
          }
          break;
        case 5:
          {
            statut = Constants.LIVREE;
          }
          break;
        case 6:
          {
            statut = Constants.ANNULEE;
          }
          break;
        default:
          break;
      }
      this.$router.push({
        name: "Orders",
        params: { stateOrder: statut },
      });
    },
    async updateChartpie() {

      var generalStats = await FirebaseDB.getGeneralStats();

      generalStats.forEach((stat) => {
        this.series.push(stat.data().number_orders_created);
        this.series.push(stat.data().number_orders_in_preparation);
        this.series.push(stat.data().number_orders_awaiting_delivery);
        this.series.push(stat.data().number_orders_out_for_delivery);
        this.series.push(stat.data().number_orders_returned);
        this.series.push(stat.data().number_orders_livery);
        this.series.push(stat.data().number_orders_canceled);
      });
    },
  },
  beforeMount() {
    this.updateChartpie();
  },
};
</script>
